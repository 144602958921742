@import url(~normalize.css/normalize.css);
@import url(~@blueprintjs/icons/lib/css/blueprint-icons.css);
@import url(~@blueprintjs/core/lib/css/blueprint.css);
@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-ms-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.ls-avatar {
  display: inline-block;
  background: #4eb3d4;
  color: #ffffff;
  line-height: normal;
  border-radius: 50%;
  text-align: center;
  font-weight: 500;
  font-family: 'tyepaloon', monospace;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .ls-avatar .rounded-circle {
    border-radius: 50%; }
  .ls-avatar.sm {
    width: 25px;
    height: 25px;
    font-size: 10px; }
    .ls-avatar.sm img {
      width: 100%;
      height: 100%; }
    .ls-avatar.sm .initials-avatar {
      line-height: 22px;
      height: 100%; }
    .ls-avatar.sm i {
      line-height: 22px;
      height: 100%; }
  .ls-avatar.md {
    width: 45px;
    height: 45px;
    font-size: 18px; }
    .ls-avatar.md img {
      width: 100%;
      height: 100%; }
    .ls-avatar.md .initials-avatar {
      line-height: 45px;
      height: 100%; }
    .ls-avatar.md i {
      line-height: 45px;
      height: 100%; }
  .ls-avatar.lg {
    width: 75px;
    height: 75px;
    font-size: 36px; }
    .ls-avatar.lg img {
      width: 100%;
      height: 100%; }
    .ls-avatar.lg .initials-avatar {
      line-height: 74px;
      height: 100%; }
    .ls-avatar.lg i {
      line-height: 74px;
      height: 100%; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-ms-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.banner {
  padding: 1rem;
  background-color: #00acc1;
  filter: drop-shadow(0rem 0.05rem 0.75rem grey);
  display: flex;
  justify-content: center;
  align-items: center; }

.banner-copy {
  color: white;
  font-size: 1.25rem;
  margin: 0;
  font-weight: 700; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-ms-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.ls-button {
  font: 14px "GothamMedium", sans-serif;
  line-height: normal;
  font-weight: 900;
  padding: 10px 40px;
  background: #4eb3d4;
  color: #ffffff;
  border-color: transparent;
  border-radius: 60px;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  cursor: pointer;
  outline: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-height: 24px;
  line-height: normal;
  outline: none !important; }
  .ls-button:hover {
    opacity: 0.9; }
  .ls-button.clear {
    background: transparent;
    color: #4eb3d4;
    border: 2px solid #4eb3d4; }
    .ls-button.clear:hover {
      background: #4eb3d4;
      color: #ffffff; }
  .ls-button.clear.salmon {
    background: transparent;
    color: #f16153;
    border: 2px solid #f16153; }
    .ls-button.clear.salmon:hover {
      background: #f16153;
      color: #ffffff; }
  .ls-button.clear.slate {
    background: transparent;
    color: #212126;
    border: 2px solid #212126; }
    .ls-button.clear.slate:hover {
      background: #212126;
      color: #ffffff; }
  .ls-button:focus, .ls-button:active {
    outline: 0; }
  .ls-button.small {
    padding: 5px 20px !important; }
  .ls-button:disabled {
    opacity: 0.5;
    cursor: not-allowed; }
  .ls-button:active {
    outline: none !important; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-ms-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.ls-card {
  font: 14px "Gotham", sans-serif;
  line-height: normal;
  font-weight: 400;
  position: relative;
  display: inline-block;
  margin-bottom: 24px;
  cursor: default;
  min-width: 200px;
  background: #ffffff;
  box-shadow: 0 0 10px 0 #eaeaea;
  border-radius: 13px;
  overflow: hidden; }
  .ls-card.cutout-right {
    overflow: visible; }
    .ls-card.cutout-right:after {
      display: block;
      content: '';
      position: absolute;
      top: 55px;
      right: -10px;
      width: 52px;
      height: 60%;
      border-radius: 13px;
      background: #f4f7f9;
      z-index: 999; }
    .ls-card.cutout-right .cutout {
      display: block;
      position: absolute;
      top: 55px;
      right: -10px;
      width: 52px;
      height: 60%;
      border-radius: 13px;
      background: #f4f7f9;
      z-index: 999; }
  .ls-card.cutout-left {
    overflow: visible; }
    .ls-card.cutout-left:after {
      display: block;
      content: '';
      position: absolute;
      top: 55px;
      left: -10px;
      width: 52px;
      height: 60%;
      border-radius: 13px;
      background: #f4f7f9;
      z-index: 999; }
    .ls-card.cutout-left .cutout {
      display: block;
      position: absolute;
      top: 55px;
      left: -10px;
      width: 52px;
      height: 60%;
      border-radius: 13px;
      background: #f4f7f9;
      z-index: 999; }
  .ls-card .card-image {
    max-height: 270px;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    overflow: hidden; }
  .ls-card img {
    height: 202px;
    width: 101%;
    margin: 0 -2px -5px;
    cursor: pointer; }
  .ls-card .card-body {
    position: relative;
    padding: 20px 20px 15px; }
    .ls-card .card-body .card-title,
    .ls-card .card-body .card-header {
      margin-top: 0;
      font-weight: 500; }
  .ls-card .card-footer {
    position: relative;
    padding: 15px 20px;
    font-size: 14px;
    min-height: 18px; }
    .ls-card .card-footer .card-icon i {
      position: absolute;
      top: 14px;
      right: 20px;
      font-size: 20px;
      color: #212126;
      cursor: pointer; }
    .ls-card .card-footer .card-icon i.left {
      left: 20px; }
    .ls-card .card-footer .card-icon:focus, .ls-card .card-footer .card-icon:active {
      opacity: 0.6; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-ms-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.cyber-dropdown {
  overflow: hidden; }

.cyber-dropdown-item {
  padding: 10px 20px; }
  .cyber-dropdown-item.active, .cyber-dropdown-item:active {
    background-color: #4eb3d4; }

button:focus {
  outline: 3px auto #4eb3d4 !important; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-ms-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.leaderboard-dropdown--container {
  width: 20rem;
  min-width: 10rem;
  margin-right: 2rem;
  padding: 0.5rem 0 0;
  position: relative; }

.dropdown--container {
  width: 100%;
  color: #00acc1;
  font-size: 1.25rem;
  position: relative;
  cursor: pointer;
  padding: 0;
  border: 1px solid #00acc1; }

.dropdown--value-container {
  transition: all 0.2s;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.9rem;
  padding: 0.7rem 1.5rem 0.7rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .dropdown--value-container .fa-sort {
    margin-left: 2rem;
    color: #474f55; }
  .dropdown--value-container .fa-sort:hover {
    color: #00acc1; }

.dropdown--list {
  transform: scale(1);
  transform-origin: top center;
  position: absolute;
  z-index: 20;
  background: #fff;
  min-width: 100%;
  transition: 0.1s;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.15);
  border-radius: 0 0 2px 2px;
  max-height: 20rem;
  overflow-y: auto;
  padding: 0.5rem 0;
  margin: 0; }

.dropdown--list-item {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  color: #474f55; }

.dropdown--list-item:hover {
  color: #00acc1; }

label {
  padding: 0 1rem;
  margin: 0; }
  label i {
    font-size: 0.75rem; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-ms-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.Dropdown--container {
  width: 100%;
  min-width: 10rem;
  max-width: 20rem;
  position: relative; }

.Dropdown--toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border: 1px solid #00acc1;
  color: black;
  font-size: 1rem;
  cursor: pointer; }

.Dropdown--menu-container {
  transform: scale(1);
  transform-origin: top center;
  position: absolute;
  z-index: 20;
  background: #fff;
  width: 100%;
  min-width: 10rem;
  max-width: 20rem;
  transition: 0.1s;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.15);
  border-radius: 0 0 2px 2px;
  max-height: 20rem;
  overflow-y: auto;
  padding: 0.5rem 0;
  margin: 0; }

.Dropdown--menu {
  list-style: none;
  margin: 0;
  padding: 0; }
  .Dropdown--menu .Dropdown--menu-item {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    color: #474f55; }
  .Dropdown--menu .Dropdown--menu-item:hover {
    color: #00acc1;
    background-color: #f8f9fa; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-ms-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.content-dropdowns--container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  float: left;
  margin-bottom: 10px;
  box-sizing: border-box; }

.content-dropdowns,
.content-dropdowns--current-container {
  display: flex; }

.content-dropdowns--dropdown {
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer; }
  .content-dropdowns--dropdown .content-dropdowns-icon {
    color: #4eb3d4;
    margin-right: 1.5rem; }

.content-dropdowns--label {
  color: #96a6b3;
  font-weight: 700;
  margin-right: 0.5rem; }

.content-dropdowns--current {
  color: #4eb3d4;
  margin-right: 0.5rem; }

.content-dropdowns--dropdown:hover .content-dropdowns--label {
  color: #4eb3d4; }

.content-dropdowns--dropdown:hover .content-dropdowns--current,
.content-dropdowns--dropdown:hover .content-dropdowns-icon {
  color: #4eb3d4; }

.content-dropdowns--list {
  transform: scale(1);
  transform-origin: top center;
  position: absolute;
  z-index: 20;
  background: #fff;
  width: 12rem;
  transition: 0.1s;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.15);
  border-radius: 0 0 2px 2px;
  max-height: 20rem;
  overflow-y: auto;
  padding: 0.5rem 0;
  margin: 0.5rem 0 0; }

.content-dropdowns--list-item {
  color: #151515;
  padding: 0.5rem 1rem; }

.content-dropdowns--list-item:hover {
  color: #00acc1;
  background-color: #f8f9fa;
  cursor: pointer; }

.content-dropdowns--list-background {
  top: 0;
  left: 0;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0);
  z-index: 19; }

.content-dropdowns--filter-button {
  background: none;
  border: none;
  padding: 0;
  color: #96a6b3; }

.content-dropdowns--filter-button:hover {
  color: #00acc1; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-ms-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.dropzone-container {
  display: flex;
  min-width: 250px;
  min-height: 50px;
  justify-content: center;
  align-items: center;
  border: 1px dashed #96a6b3;
  padding: 10px;
  cursor: pointer;
  outline: none; }

.dropzone-inner {
  text-align: center; }
  .dropzone-inner .fal {
    font-size: 35px;
    margin-bottom: 15px; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-ms-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.puzzle-icon-button--container {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 30px; }
  .puzzle-icon-button--container .puzzle-icon-button {
    border: 1px solid #4eb3d4;
    box-shadow: 3px 4px 0 #4eb3d4;
    -moz-box-shadow: 3px 4px 0 0 #4eb3d4;
    -webkit-box-shadow: 3px 4px 0 0 #4eb3d4;
    box-shadow: 3px 4px 0 0 #4eb3d4;
    padding: 5px;
    margin: 5px;
    background: white;
    color: #4eb3d4;
    cursor: pointer; }
  .puzzle-icon-button--container .puzzle-icon-button:active {
    transform: translate(3px, 4px);
    box-shadow: none; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-ms-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.ls-logo {
  display: inline-block; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-ms-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.ls-navbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  margin: 0;
  z-index: 999;
  border-bottom: 1px solid #dee2e6;
  font: 14px "Gotham", sans-serif;
  line-height: normal;
  font-weight: 400;
  -moz-box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .ls-navbar.light {
    background: #ffffff;
    color: #212126; }
  .ls-navbar.dark {
    background: #212126;
    color: #ffffff; }
  .ls-navbar.blur {
    background-color: rgba(77, 188, 222, 0.7);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    color: #ffffff; }
  .ls-navbar.clear {
    color: #ffffff; }
  .ls-navbar .navbar-logo {
    display: flex;
    align-items: center;
    height: 100%; }
    .ls-navbar .navbar-logo.align-left {
      justify-content: flex-start;
      margin-left: 20px; }
    .ls-navbar .navbar-logo.align-center {
      justify-content: center; }
    .ls-navbar .navbar-logo.align-right {
      justify-content: flex-end;
      margin-right: 20px; }
  .ls-navbar .full-menu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    margin: 0; }
  .ls-navbar .mobile-menu-btn {
    position: absolute;
    display: inline-block;
    top: 24px;
    cursor: pointer;
    z-index: 999; }
    .ls-navbar .mobile-menu-btn.align-left, .ls-navbar .mobile-menu-btn.align-center {
      right: 0;
      margin-right: 25px; }
    .ls-navbar .mobile-menu-btn.align-right {
      left: 0;
      margin-left: 25px; }
  .ls-navbar .mobile-menu {
    position: absolute;
    top: 62px;
    padding: 10px;
    min-width: 150px;
    height: auto;
    z-index: 999;
    -moz-box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1); }
    .ls-navbar .mobile-menu.align-left, .ls-navbar .mobile-menu.align-center {
      right: 0; }
    .ls-navbar .mobile-menu.align-right {
      left: 0; }
    .ls-navbar .mobile-menu.light {
      background: #ffffff;
      color: #212126; }
    .ls-navbar .mobile-menu.dark {
      background: #212126;
      color: #ffffff; }
    .ls-navbar .mobile-menu.blur {
      background-color: rgba(77, 188, 222, 0.7);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      color: #ffffff; }
    .ls-navbar .mobile-menu.clear {
      color: #ffffff; }
  .ls-navbar .navbar-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%; }
    .ls-navbar .navbar-nav.align-left {
      justify-content: flex-start;
      margin-left: 10px; }
    .ls-navbar .navbar-nav.align-center {
      justify-content: center; }
    .ls-navbar .navbar-nav.align-right {
      justify-content: flex-end;
      margin-right: 10px; }
    .ls-navbar .navbar-nav .nav-item {
      display: inline-block;
      margin: 0 -20px;
      font-size: 15px;
      line-height: 17px;
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
      .ls-navbar .navbar-nav .nav-item a,
      .ls-navbar .navbar-nav .nav-item div {
        position: relative;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        padding: 21px 2.375rem; }
      .ls-navbar .navbar-nav .nav-item a:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        -webkit-transform: translate(-50%);
        transform: translate(-50%);
        height: 4px;
        width: 0;
        background: #f16153;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s; }
      .ls-navbar .navbar-nav .nav-item a:hover:after {
        width: calc(100% - 66px); }
      .ls-navbar .navbar-nav .nav-item a.active:after {
        width: calc(100% - 66px); }

@media only screen and (min-width: 1000px) {
  .mobile-menu-btn {
    display: none !important; }
  .mobile-menu {
    display: none !important; } }

@media only screen and (max-width: 1000px) {
  .mobile-menu-btn {
    display: inline-block; }
  .full-menu {
    display: none !important; }
  .mobile-menu {
    display: block;
    overflow-y: auto; }
  .ls-navbar .navbar-nav {
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto; }
    .ls-navbar .navbar-nav.align-left {
      margin-left: 0; }
    .ls-navbar .navbar-nav.align-right {
      margin-right: 0; }
    .ls-navbar .navbar-nav .nav-item {
      display: block !important; }
      .ls-navbar .navbar-nav .nav-item a,
      .ls-navbar .navbar-nav .nav-item div {
        position: relative;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        padding: 22px 2.375rem; } }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-ms-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.paginated-table-container {
  cursor: default !important;
  outline: none !important; }
  .paginated-table-container .table-striped tbody tr:nth-of-type(odd) {
    background-color: #eff7fc; }
  .paginated-table-container .table-bordered thead td,
  .paginated-table-container .table-bordered thead th {
    outline: none; }
  .paginated-table-container .col-lg-6 {
    max-width: 25%; }
  .paginated-table-container .react-bootstrap-table-pagination-list {
    display: inline-block; }
  .paginated-table-container .pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px; }
  .paginated-table-container .dropdown .btn-secondary {
    background-color: #fff;
    border: 1px solid #4eb3d4;
    color: #4eb3d4;
    outline: none; }
    .paginated-table-container .dropdown .btn-secondary:active {
      background-color: #fff;
      border: 1px solid #4eb3d4;
      color: #4eb3d4;
      outline: none; }
    .paginated-table-container .dropdown .btn-secondary:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .paginated-table-container .dropdown-menu {
    min-width: 0;
    text-align: center; }
  .paginated-table-container .dropdown-item {
    max-width: 100%;
    padding: 0.25rem 0;
    width: auto;
    cursor: pointer; }
    .paginated-table-container .dropdown-item:active {
      background-color: #4eb3d4; }
    .paginated-table-container .dropdown-item:focus {
      outline: none; }
    .paginated-table-container .dropdown-item a {
      color: #4eb3d4 !important;
      padding: 0 1.5em;
      cursor: pointer; }
      .paginated-table-container .dropdown-item a:hover {
        color: #4eb3d4; }
  .paginated-table-container .react-bootstrap-table-page-btns-ul {
    float: right;
    margin-top: 0 !important; }
  .paginated-table-container .pagination > li {
    display: inline;
    outline: none;
    cursor: pointer; }
  .paginated-table-container .pagination > li > a,
  .paginated-table-container .pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #4eb3d4;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
    cursor: pointer; }
  .paginated-table-container .page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #4eb3d4;
    border-color: #4eb3d4; }

.spinner {
  position: absolute;
  border-radius: 100%; }

@keyframes spinnerFrame {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-ms-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#subnav-container {
  font: 14px 'Avenir', sans-serif;
  position: fixed;
  left: 0;
  bottom: 0;
  background: #e8f1f3;
  box-shadow: 3px 0 10px 0 #d1d6d9; }
  #subnav-container.closed {
    transition: all 1s; }
    #subnav-container.closed .subnav-content .subnav-title {
      transition: all 0s;
      visibility: hidden;
      opacity: 0;
      height: 25px; }
    #subnav-container.closed .subnav-content .subnav-section {
      transition: all 0s;
      margin: 5px 0 10px; }
    #subnav-container.closed .subnav-content .subnav-item-title {
      -webkit-transition: opacity 0s ease-in-out;
      -moz-transition: opacity 0s ease-in-out;
      -ms-transition: opacity 0s ease-in-out;
      -o-transition: opacity 0s ease-in-out;
      opacity: 0; }
    #subnav-container.closed .subnav-content .subnav-item {
      transition: all 0.9s;
      margin-left: -30px;
      width: 45px; }
      #subnav-container.closed .subnav-content .subnav-item.active {
        padding: 0 8px;
        margin-left: -28px; }
        #subnav-container.closed .subnav-content .subnav-item.active .subnav-item-chevron {
          right: 11px; }
  #subnav-container.open {
    transition: all 0.2s; }
    #subnav-container.open .subnav-content .subnav-item-title {
      -webkit-transition: opacity 1s ease-in-out;
      -moz-transition: opacity 1s ease-in-out;
      -ms-transition: opacity 1s ease-in-out;
      -o-transition: opacity 1s ease-in-out;
      opacity: 1; }
    #subnav-container.open .subnav-content .subnav-item {
      transition: all 0.2s; }
  #subnav-container .subnav-minimize-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -12px;
    top: 40px;
    background: #ffffff;
    border-radius: 30px;
    width: 24px;
    height: 24px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
    border: solid 0 #173042;
    cursor: pointer; }
    #subnav-container .subnav-minimize-btn .far {
      font-size: 14px; }
  #subnav-container .subnav-content {
    margin: 45px 0;
    padding: 0 28px;
    cursor: default; }
    #subnav-container .subnav-content .subnav-title {
      font-size: 11px;
      font-weight: bold;
      color: #173042; }
    #subnav-container .subnav-content .subnav-section {
      margin: 15px 0 50px; }
    #subnav-container .subnav-content .subnav-item {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 219px;
      height: 42px;
      margin: 7px 0;
      padding: 0 10px;
      font-size: 18px;
      line-height: 15px;
      cursor: pointer;
      color: #373636; }
      #subnav-container .subnav-content .subnav-item .subnav-item-chevron {
        visibility: hidden;
        position: absolute;
        right: 20px;
        font-size: 11px; }
      #subnav-container .subnav-content .subnav-item.active {
        color: #173042;
        font-weight: bold;
        box-shadow: 0 0 10px 0 #eaeaea;
        background-color: #ffffff;
        border-radius: 13px; }
        #subnav-container .subnav-content .subnav-item.active .subnav-item-chevron {
          visibility: visible; }
      #subnav-container .subnav-content .subnav-item .subnav-item-title {
        margin-left: 7px; }

.switcher-wrapper {
  font-size: 0; }

.switcher-label {
  display: inline-flex;
  align-items: center; }

.switcher-text {
  margin: 0 8px;
  font-size: 16px; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-ms-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.UserStats__container {
  display: flex;
  flex-direction: column;
  max-width: 150px;
  padding: 0.25rem 1.25rem;
  font-family: sans-serif;
  border: 2px solid #00acc1; }
  .UserStats__container .UserStats__header {
    color: #00acc1; }
  .UserStats__container .UserStats_AllData__container {
    display: flex;
    justify-content: space-between;
    width: 100%; }
  .UserStats__container .UserStats_data__container {
    text-align: center; }
  .UserStats__container .UserStats_data__label {
    font-weight: 600; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-moz-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-ms-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-o-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

#puzzle-modal--container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; }

#puzzle-modal {
  font: 14px "Gotham", sans-serif;
  line-height: normal;
  font-weight: 400;
  background: #ffffff;
  min-width: 30rem;
  max-width: 35rem;
  padding: 2rem;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  z-index: 2;
  font-size: 1rem;
  line-height: 1.5rem;
  border: 1px solid #4eb3d4;
  box-shadow: 3px 4px 0 #4eb3d4;
  -moz-box-shadow: 3px 4px 0 0 #4eb3d4;
  -webkit-box-shadow: 3px 4px 0 0 #4eb3d4;
  box-shadow: 3px 4px 0 0 #4eb3d4; }

#puzzle-modal h2 {
  font-size: 1.5rem;
  color: #4eb3d4;
  margin: 1rem 0 0; }

#puzzle-modal img {
  margin: 1rem 0 0;
  width: 450px; }

.puzzleinstructionsmodal-caption {
  font-size: 1rem;
  font-style: italic;
  margin: 1rem 0; }

#puzzle-modal .puzzleinstructionsmodal-title {
  font-weight: 700; }

#puzzle-modal .puzzleinstructionsmodal-text {
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  padding: 0 2rem;
  margin: 0; }
  #puzzle-modal .puzzleinstructionsmodal-text p {
    margin-bottom: 2rem; }

#puzzle-modal .puzzleinstructionsmodal-copy--button {
  font: 14px "GothamMedium", sans-serif;
  line-height: normal;
  font-weight: 900;
  padding: 10px 40px;
  background: #4eb3d4;
  color: #ffffff;
  border-color: transparent;
  border-radius: 60px;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  cursor: pointer;
  outline: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 8.75rem;
  padding: 0.625rem 0;
  font-weight: 500;
  margin: 1.25rem auto 0.3125rem; }
  #puzzle-modal .puzzleinstructionsmodal-copy--button:hover {
    opacity: 0.9; }
  #puzzle-modal .puzzleinstructionsmodal-copy--button.clear {
    background: transparent;
    color: #4eb3d4;
    border: 2px solid #4eb3d4; }
    #puzzle-modal .puzzleinstructionsmodal-copy--button.clear:hover {
      background: #4eb3d4;
      color: #ffffff; }
  #puzzle-modal .puzzleinstructionsmodal-copy--button.clear.salmon {
    background: transparent;
    color: #f16153;
    border: 2px solid #f16153; }
    #puzzle-modal .puzzleinstructionsmodal-copy--button.clear.salmon:hover {
      background: #f16153;
      color: #ffffff; }
  #puzzle-modal .puzzleinstructionsmodal-copy--button.clear.slate {
    background: transparent;
    color: #212126;
    border: 2px solid #212126; }
    #puzzle-modal .puzzleinstructionsmodal-copy--button.clear.slate:hover {
      background: #212126;
      color: #ffffff; }
  #puzzle-modal .puzzleinstructionsmodal-copy--button:focus, #puzzle-modal .puzzleinstructionsmodal-copy--button:active {
    outline: 0; }
  #puzzle-modal .puzzleinstructionsmodal-copy--button:active {
    -webkit-box-shadow: inset 1px 1px 3px #474f55;
    -moz-box-shadow: inset 1px 1px 3px #474f55;
    box-shadow: inset 1px 1px 3px #474f55; }

.puzzleinstructionsmodal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.8); }

.puzzleinstructionsmodal-button {
  font: 14px "GothamMedium", sans-serif;
  line-height: normal;
  font-weight: 900;
  padding: 10px 40px;
  background: #4eb3d4;
  color: #ffffff;
  border-color: transparent;
  border-radius: 60px;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  cursor: pointer;
  outline: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .puzzleinstructionsmodal-button:hover {
    opacity: 0.9; }
  .puzzleinstructionsmodal-button.clear {
    background: transparent;
    color: #4eb3d4;
    border: 2px solid #4eb3d4; }
    .puzzleinstructionsmodal-button.clear:hover {
      background: #4eb3d4;
      color: #ffffff; }
  .puzzleinstructionsmodal-button.clear.salmon {
    background: transparent;
    color: #f16153;
    border: 2px solid #f16153; }
    .puzzleinstructionsmodal-button.clear.salmon:hover {
      background: #f16153;
      color: #ffffff; }
  .puzzleinstructionsmodal-button.clear.slate {
    background: transparent;
    color: #212126;
    border: 2px solid #212126; }
    .puzzleinstructionsmodal-button.clear.slate:hover {
      background: #212126;
      color: #ffffff; }
  .puzzleinstructionsmodal-button:focus, .puzzleinstructionsmodal-button:active {
    outline: 0; }

button,
.puzzleinstructionsmodal-button:hover,
.puzzleinstructionsmodal-button:active,
.puzzleinstructionsmodal-button:focus {
  outline: none !important; }

.puzzleinstructionsmodal-button--container {
  display: flex;
  justify-content: center;
  align-items: center; }

.puzzleinstructionsmodal-progress {
  transform: translateY(-4.5rem);
  background: #f4f4f4;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: -4rem; }

.puzzleinstructionsmodal-progress > p {
  margin: 2rem 0 0; }

.security-score-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .security-score-footer .level-container {
    font-weight: 500; }
    .security-score-footer .level-container .fad {
      margin-right: 5px; }
  .security-score-footer .change-container {
    font-size: 13px;
    line-height: 22px; }
    .security-score-footer .change-container .far {
      margin-left: 5px; }

.security-score-by-category-labels {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 202px;
  width: 300px;
  line-height: 21px;
  padding: 26px 0;
  text-align: right; }

#root > div > div > div > div > svg > g:nth-child(2) > line {
  display: none; }

#root > div > div > div > div > svg > g:nth-child(3) > line {
  display: none; }

.bottom-rank-graph {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px; }
  .bottom-rank-graph .bottom-rank-graph-left-nav {
    display: flex;
    flex-direction: column;
    width: 25%;
    min-width: 80px;
    height: 100%;
    text-align: right;
    text-transform: capitalize;
    padding: 35px 20px;
    background: #edf7fb;
    border-right: 2px solid #4eb3d4;
    align-items: flex-end;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px; }
    .bottom-rank-graph .bottom-rank-graph-left-nav .bottom-rank-graph-left-nav-item {
      margin: 0 -40px 5px 0;
      padding: 10px 30px 10px 7px;
      border-radius: 13px;
      width: 180px; }
      .bottom-rank-graph .bottom-rank-graph-left-nav .bottom-rank-graph-left-nav-item .fal {
        display: none;
        float: right;
        font-size: 10px;
        margin-right: -20px;
        line-height: 14px;
        font-weight: 600; }
      .bottom-rank-graph .bottom-rank-graph-left-nav .bottom-rank-graph-left-nav-item.active, .bottom-rank-graph .bottom-rank-graph-left-nav .bottom-rank-graph-left-nav-item:hover {
        background: white;
        box-shadow: 3px 0 10px 0 #eaeaea;
        font-weight: 500;
        cursor: pointer; }
        .bottom-rank-graph .bottom-rank-graph-left-nav .bottom-rank-graph-left-nav-item.active .fal, .bottom-rank-graph .bottom-rank-graph-left-nav .bottom-rank-graph-left-nav-item:hover .fal {
          display: block; }
  .bottom-rank-graph .bottom-rank-graph-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 80%;
    height: 280px;
    min-width: 224px;
    padding: 20px 60px 20px 50px; }
    .bottom-rank-graph .bottom-rank-graph-body .no-data {
      margin: 100px auto; }
    .bottom-rank-graph .bottom-rank-graph-body .headers {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 20px;
      font-weight: 500; }
    .bottom-rank-graph .bottom-rank-graph-body .body {
      overflow-x: hidden; }
    .bottom-rank-graph .bottom-rank-graph-body .bottom-rank-body-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 9px 20px;
      border-radius: 10px;
      margin: 3px -20px; }
      .bottom-rank-graph .bottom-rank-graph-body .bottom-rank-body-row:nth-child(even) {
        background: #f4f7f9; }
      .bottom-rank-graph .bottom-rank-graph-body .bottom-rank-body-row .rank-score {
        display: inline-flex;
        min-width: 30px;
        text-align: center;
        justify-content: center; }
      .bottom-rank-graph .bottom-rank-graph-body .bottom-rank-body-row .fa-long-arrow-up {
        color: #4ed4b2; }
      .bottom-rank-graph .bottom-rank-graph-body .bottom-rank-body-row .fa-long-arrow-down {
        color: #3d68d3; }

.graph-placeholder {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(6px); }
  .graph-placeholder.loaded {
    transition: all 0.2s;
    backdrop-filter: none;
    background-color: rgba(255, 255, 255, 0); }

.security-score-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .security-score-footer .level-container {
    font-weight: 500; }
    .security-score-footer .level-container .fad {
      margin-right: 5px; }
  .security-score-footer .change-container {
    font-size: 13px;
    line-height: 22px; }
    .security-score-footer .change-container .far {
      margin-left: 5px; }

.bottom-rank-graph {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px; }
  .bottom-rank-graph .bottom-rank-graph-left-nav {
    display: flex;
    flex-direction: column;
    width: 25%;
    min-width: 80px;
    height: 100%;
    text-align: right;
    text-transform: capitalize;
    padding: 35px 20px;
    background: #edf7fb;
    border-right: 2px solid #4eb3d4;
    align-items: flex-end;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px; }
    .bottom-rank-graph .bottom-rank-graph-left-nav .bottom-rank-graph-left-nav-item {
      margin: 0 -40px 5px 0;
      padding: 10px 30px 10px 7px;
      border-radius: 13px;
      width: 180px; }
      .bottom-rank-graph .bottom-rank-graph-left-nav .bottom-rank-graph-left-nav-item .fal {
        display: none;
        float: right;
        font-size: 10px;
        margin-right: -20px;
        line-height: 14px;
        font-weight: 600; }
      .bottom-rank-graph .bottom-rank-graph-left-nav .bottom-rank-graph-left-nav-item.active, .bottom-rank-graph .bottom-rank-graph-left-nav .bottom-rank-graph-left-nav-item:hover {
        background: white;
        box-shadow: 3px 0 10px 0 #eaeaea;
        font-weight: 500;
        cursor: pointer; }
        .bottom-rank-graph .bottom-rank-graph-left-nav .bottom-rank-graph-left-nav-item.active .fal, .bottom-rank-graph .bottom-rank-graph-left-nav .bottom-rank-graph-left-nav-item:hover .fal {
          display: block; }
  .bottom-rank-graph .bottom-rank-graph-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 80%;
    height: 280px;
    min-width: 224px;
    padding: 20px 60px 20px 50px; }
    .bottom-rank-graph .bottom-rank-graph-body .no-data {
      margin: 100px auto; }
    .bottom-rank-graph .bottom-rank-graph-body .headers {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 20px;
      font-weight: 500; }
    .bottom-rank-graph .bottom-rank-graph-body .body {
      overflow-x: hidden; }
    .bottom-rank-graph .bottom-rank-graph-body .bottom-rank-body-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 9px 20px;
      border-radius: 10px;
      margin: 3px -20px; }
      .bottom-rank-graph .bottom-rank-graph-body .bottom-rank-body-row:nth-child(even) {
        background: #f4f7f9; }
      .bottom-rank-graph .bottom-rank-graph-body .bottom-rank-body-row .rank-score {
        display: inline-flex;
        min-width: 30px;
        text-align: center;
        justify-content: center; }
      .bottom-rank-graph .bottom-rank-graph-body .bottom-rank-body-row .fa-long-arrow-up {
        color: #4ed4b2; }
      .bottom-rank-graph .bottom-rank-graph-body .bottom-rank-body-row .fa-long-arrow-down {
        color: #3d68d3; }

.security-score-by-category-labels {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 202px;
  width: 300px;
  line-height: 21px;
  padding: 26px 0;
  text-align: right; }

#root > div > div > div > div > svg > g:nth-child(2) > line {
  display: none; }

#root > div > div > div > div > svg > g:nth-child(3) > line {
  display: none; }

.assigned-completion-rate-graph {
  font-size: 80px;
  font-weight: 500;
  text-align: center;
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }

.low-progress-assigned {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%; }
  .low-progress-assigned .top-toggle {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background: #e8eaec;
    padding: 6px 0;
    margin: 10px 0 15px;
    line-height: 25px;
    width: 350px;
    border-radius: 10px; }
    .low-progress-assigned .top-toggle div {
      padding: 3px 30px;
      cursor: pointer; }
    .low-progress-assigned .top-toggle .active {
      background: #ffffff;
      border-radius: 5px;
      font-weight: 500; }
  .low-progress-assigned .graph-headers {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    font-size: 11px;
    margin-bottom: 20px; }
    .low-progress-assigned .graph-headers .left-header {
      font-weight: 500;
      width: 20%;
      text-align: right; }
    .low-progress-assigned .graph-headers .center-header {
      display: flex;
      justify-content: center;
      width: 60%;
      min-width: 500px;
      padding: 0 10px; }
      .low-progress-assigned .graph-headers .center-header .legend-item {
        display: flex;
        flex-direction: row;
        padding: 0 10px; }
        .low-progress-assigned .graph-headers .center-header .legend-item .color-swatch {
          width: 8px;
          height: 8px;
          border-radius: 2px;
          margin: 3px 8px; }
    .low-progress-assigned .graph-headers .right-header {
      font-weight: 500;
      width: 20%;
      padding-right: 15px; }
  .low-progress-assigned .low-progress-graph {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
    margin-right: -15px;
    width: 100%;
    height: calc(100% - 200px);
    font-size: 12px; }
    .low-progress-assigned .low-progress-graph .low-progress-graph-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%; }
    .low-progress-assigned .low-progress-graph .item-name {
      width: 20%;
      text-align: right; }
    .low-progress-assigned .low-progress-graph .horizontal-bar-graph {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      margin: 6px 40px 6px 30px;
      border-radius: 10px;
      cursor: pointer; }
      .low-progress-assigned .low-progress-graph .horizontal-bar-graph .complete-bar {
        background: #4eb3d4;
        width: 50%;
        height: 24px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px; }
      .low-progress-assigned .low-progress-graph .horizontal-bar-graph .incomplete-bar {
        background: #3d68d3;
        width: 50%;
        height: 24px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px; }
    .low-progress-assigned .low-progress-graph .item-score {
      width: 18%; }
    .low-progress-assigned .low-progress-graph .low-progress-timeline-row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 60px; }
      .low-progress-assigned .low-progress-graph .low-progress-timeline-row .timeline-title {
        width: 20%;
        text-align: right;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 500; }
      .low-progress-assigned .low-progress-graph .low-progress-timeline-row .timeline {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: 0;
        margin: 0 40px 0 30px;
        padding: 2px;
        justify-content: center;
        align-items: flex-start;
        overflow-y: hidden; }
        .low-progress-assigned .low-progress-graph .low-progress-timeline-row .timeline ::-webkit-scrollbar {
          width: 1px; }
        .low-progress-assigned .low-progress-graph .low-progress-timeline-row .timeline .line-top {
          height: 5px;
          width: 100%;
          border-left: 1px solid;
          border-right: 1px solid; }
        .low-progress-assigned .low-progress-graph .low-progress-timeline-row .timeline .line-bottom {
          border-top: 1px solid;
          height: 5px;
          width: 100%;
          border-left: 1px solid;
          border-right: 1px solid; }
        .low-progress-assigned .low-progress-graph .low-progress-timeline-row .timeline .dates {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          font-size: 10px;
          width: 100%; }
          .low-progress-assigned .low-progress-graph .low-progress-timeline-row .timeline .dates .timeline-item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 35px; }
          .low-progress-assigned .low-progress-graph .low-progress-timeline-row .timeline .dates .dot {
            height: 10px;
            width: 10px;
            border-radius: 30px;
            background: #4eb3d4;
            margin: -10px 0 5px; }
        .low-progress-assigned .low-progress-graph .low-progress-timeline-row .timeline.wide {
          width: calc(100% - 440px);
          align-items: flex-start; }
          .low-progress-assigned .low-progress-graph .low-progress-timeline-row .timeline.wide .line-top {
            width: 100vw;
            margin: 0 10px; }
          .low-progress-assigned .low-progress-graph .low-progress-timeline-row .timeline.wide .line-bottom {
            width: 100vw;
            margin: 0 10px; }
          .low-progress-assigned .low-progress-graph .low-progress-timeline-row .timeline.wide .dates {
            width: 100vw;
            margin: 0 10px; }
      .low-progress-assigned .low-progress-graph .low-progress-timeline-row .end-filler {
        width: 18%; }
  .low-progress-assigned .graph-footers {
    display: flex;
    flex-direction: column;
    width: 65%;
    font-size: 11px;
    font-weight: 500; }
    .low-progress-assigned .graph-footers .graph-ticks {
      display: flex;
      flex-direction: row;
      margin: 20px -10px 8px 35px;
      padding-right: 15px; }
      .low-progress-assigned .graph-footers .graph-ticks .graph-tick {
        width: 10%;
        min-width: 30px;
        text-align: center; }
    .low-progress-assigned .graph-footers .axis-label {
      display: flex;
      justify-content: center; }

.PresetDateRangePicker_panel {
  padding: 0 22px 11px; }

.PresetDateRangePicker_button {
  position: relative;
  height: 100%;
  text-align: center;
  background: 0 0;
  border: 2px solid #00a699;
  color: #00a699;
  padding: 4px 12px;
  margin-right: 8px;
  font: inherit;
  font-weight: 700;
  line-height: normal;
  overflow: visible;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer; }

.PresetDateRangePicker_button:active {
  outline: 0; }

.PresetDateRangePicker_button__selected {
  color: #fff;
  background: #00a699; }

.DayPickerKeyboardShortcuts_buttonReset {
  background: 0 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  font-size: 14px; }

.DayPickerKeyboardShortcuts_buttonReset:active {
  outline: 0; }

.DayPickerKeyboardShortcuts_show {
  width: 33px;
  height: 26px;
  position: absolute;
  z-index: 2; }

.DayPickerKeyboardShortcuts_show::before {
  content: '';
  display: block;
  position: absolute; }

.DayPickerKeyboardShortcuts_show__bottomRight {
  bottom: 0;
  right: 0; }

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-top: 26px solid transparent;
  border-right: 33px solid #00a699;
  bottom: 0;
  right: 0; }

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right: 33px solid #008489; }

.DayPickerKeyboardShortcuts_show__topRight {
  top: 0;
  right: 0; }

.DayPickerKeyboardShortcuts_show__topRight::before {
  border-bottom: 26px solid transparent;
  border-right: 33px solid #00a699;
  top: 0;
  right: 0; }

.DayPickerKeyboardShortcuts_show__topRight:hover::before {
  border-right: 33px solid #008489; }

.DayPickerKeyboardShortcuts_show__topLeft {
  top: 0;
  left: 0; }

.DayPickerKeyboardShortcuts_show__topLeft::before {
  border-bottom: 26px solid transparent;
  border-left: 33px solid #00a699;
  top: 0;
  left: 0; }

.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
  border-left: 33px solid #008489; }

.DayPickerKeyboardShortcuts_showSpan {
  color: #fff;
  position: absolute; }

.DayPickerKeyboardShortcuts_showSpan__bottomRight {
  bottom: 0;
  right: 5px; }

.DayPickerKeyboardShortcuts_showSpan__topRight {
  top: 1px;
  right: 5px; }

.DayPickerKeyboardShortcuts_showSpan__topLeft {
  top: 1px;
  left: 5px; }

.DayPickerKeyboardShortcuts_panel {
  overflow: auto;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding: 22px;
  margin: 33px;
  text-align: left; }

.DayPickerKeyboardShortcuts_title {
  font-size: 16px;
  font-weight: 700;
  margin: 0; }

.DayPickerKeyboardShortcuts_list {
  list-style: none;
  padding: 0;
  font-size: 14px; }

.DayPickerKeyboardShortcuts_close {
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 2; }

.DayPickerKeyboardShortcuts_close:active {
  outline: 0; }

.DayPickerKeyboardShortcuts_closeSvg {
  height: 15px;
  width: 15px;
  fill: #cacccd; }

.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: #82888a; }

.CalendarDay {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: center; }

.CalendarDay:active {
  outline: 0; }

.CalendarDay__defaultCursor {
  cursor: default; }

.CalendarDay__default {
  border: 1px solid #e4e7e7;
  color: #484848;
  background: #fff; }

.CalendarDay__default:hover {
  background: #e4e7e7;
  border: 1px solid #e4e7e7;
  color: inherit; }

.CalendarDay__hovered_offset {
  background: #f4f5f5;
  border: 1px double #e4e7e7;
  color: inherit; }

.CalendarDay__outside {
  border: 0;
  background: #fff;
  color: #484848; }

.CalendarDay__outside:hover {
  border: 0; }

.CalendarDay__blocked_minimum_nights {
  background: #fff;
  border: 1px solid #eceeee;
  color: #cacccd; }

.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
  background: #fff;
  color: #cacccd; }

.CalendarDay__highlighted_calendar {
  background: #ffe8bc;
  color: #484848; }

.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #ffce71;
  color: #484848; }

.CalendarDay__selected_span {
  background: #66e2da;
  border: 1px double #33dacd;
  color: #fff; }

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #33dacd;
  outline: 1px double #33dacd;
  color: #fff; }

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #00a699;
  border: 1px double #00a699;
  color: #fff; }

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #b2f1ec;
  border: 1px double #80e8e0;
  color: #007a87; }

.CalendarDay__hovered_span:active {
  background: #80e8e0;
  border: 1px double #80e8e0;
  color: #007a87; }

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #cacccd;
  border: 1px solid #cacccd;
  color: #82888a; }

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #fff;
  border: 1px solid #e4e7e7;
  color: #cacccd; }

.CalendarDay__hovered_start_first_possible_end {
  background: #eceeee;
  border: 1px double #eceeee; }

.CalendarDay__hovered_start_blocked_min_nights {
  background: #eceeee;
  border: 1px double #e4e7e7; }

.CalendarMonth {
  background: #fff;
  text-align: center;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0; }

.CalendarMonth_verticalSpacing {
  border-collapse: separate; }

.CalendarMonth_caption {
  color: #484848;
  font-size: 18px;
  text-align: center;
  padding-top: 22px;
  padding-bottom: 37px;
  caption-side: initial; }

.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px;
  padding-bottom: 7px; }

.CalendarMonthGrid {
  background: #fff;
  text-align: left;
  z-index: 0; }

.CalendarMonthGrid__animating {
  z-index: 1; }

.CalendarMonthGrid__horizontal {
  position: absolute;
  left: 9px; }

.CalendarMonthGrid__vertical,
.CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto; }

.CalendarMonthGrid_month__horizontal {
  display: inline-block;
  vertical-align: top;
  min-height: 100%; }

.CalendarMonthGrid_month__hideForAnimation {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none; }

.CalendarMonthGrid_month__hidden {
  visibility: hidden; }

.DayPickerNavigation {
  position: relative;
  z-index: 2; }

.DayPickerNavigation__horizontal {
  height: 0; }

.DayPickerNavigation__verticalScrollable_prevNav {
  z-index: 1; }

.DayPickerNavigation__verticalDefault {
  position: absolute;
  width: 100%;
  height: 52px;
  bottom: 0;
  left: 0; }

.DayPickerNavigation__verticalScrollableDefault {
  position: relative; }

.DayPickerNavigation__bottom {
  height: auto; }

.DayPickerNavigation__bottomDefault {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between; }

.DayPickerNavigation_button {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0;
  margin: 0; }

.DayPickerNavigation_button__default {
  border: 1px solid #e4e7e7;
  background-color: #fff;
  color: #757575; }

.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border: 1px solid #c4c4c4; }

.DayPickerNavigation_button__default:active {
  background: #f2f2f2; }

.DayPickerNavigation_button__disabled {
  cursor: default;
  border: 1px solid #f2f2f2; }

.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
  border: 1px solid #f2f2f2; }

.DayPickerNavigation_button__disabled:active {
  background: 0 0; }

.DayPickerNavigation_button__horizontalDefault {
  position: absolute;
  top: 18px;
  line-height: 0.78;
  border-radius: 3px;
  padding: 6px 9px; }

.DayPickerNavigation_bottomButton__horizontalDefault {
  position: static;
  margin: -10px 22px 30px; }

.DayPickerNavigation_leftButton__horizontalDefault {
  left: 22px; }

.DayPickerNavigation_rightButton__horizontalDefault {
  right: 22px; }

.DayPickerNavigation_button__verticalDefault {
  padding: 5px;
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  display: inline-block;
  text-align: center;
  height: 100%;
  width: 50%; }

.DayPickerNavigation_nextButton__verticalDefault {
  border-left: 0; }

.DayPickerNavigation_nextButton__verticalScrollableDefault,
.DayPickerNavigation_prevButton__verticalScrollableDefault {
  width: 100%; }

.DayPickerNavigation_svg__horizontal {
  height: 19px;
  width: 19px;
  fill: #82888a;
  display: block; }

.DayPickerNavigation_svg__vertical {
  height: 42px;
  width: 42px;
  fill: #484848; }

.DayPickerNavigation_svg__disabled {
  fill: #f2f2f2; }

.DayPicker {
  background: #fff;
  position: relative;
  text-align: left; }

.DayPicker__horizontal {
  background: #fff; }

.DayPicker__verticalScrollable {
  height: 100%; }

.DayPicker__hidden {
  visibility: hidden; }

.DayPicker_portal__horizontal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%; }

.DayPicker_portal__vertical {
  position: initial; }

.DayPicker_focusRegion {
  outline: 0; }

.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
  display: inline-block;
  vertical-align: top; }

.DayPicker_weekHeaders {
  position: relative; }

.DayPicker_weekHeaders__horizontal {
  margin-left: 9px; }

.DayPicker_weekHeader {
  color: #757575;
  position: absolute;
  top: 62px;
  z-index: 2;
  text-align: left; }

.DayPicker_weekHeader__vertical {
  left: 50%; }

.DayPicker_weekHeader__verticalScrollable {
  top: 0;
  display: table-row;
  border-bottom: 1px solid #dbdbdb;
  background: #fff;
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center; }

.DayPicker_weekHeader_ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px; }

.DayPicker_weekHeader_li {
  display: inline-block;
  text-align: center; }

.DayPicker_transitionContainer {
  position: relative;
  overflow: hidden;
  border-radius: 3px; }

.DayPicker_transitionContainer__horizontal {
  -webkit-transition: height 0.2s ease-in-out;
  -moz-transition: height 0.2s ease-in-out;
  transition: height 0.2s ease-in-out; }

.DayPicker_transitionContainer__vertical {
  width: 100%; }

.DayPicker_transitionContainer__verticalScrollable {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll; }

.DateInput {
  margin: 0;
  padding: 0;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background: #dfeef2;
  width: 79px; }

.DateInput__small {
  width: 97px; }

.DateInput__block {
  width: 100%; }

.DateInput__disabled {
  background: #f2f2f2;
  color: #dbdbdb; }

.DateInput_input {
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #000;
  text-align: center;
  padding: 0;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  border-radius: 0;
  background-color: #dfeef2;
  width: 70px; }

.DateInput_input__small {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.2px;
  padding: 7px 7px 5px; }

.DateInput_input__regular {
  font-weight: 400; }

.DateInput_input__readOnly {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.DateInput_input__focused {
  outline: 0;
  background: #fff; }

.DateInput_input__disabled {
  background: #f2f2f2;
  font-style: italic; }

.DateInput_screenReaderMessage {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.DateInput_fang {
  position: absolute;
  width: 20px;
  height: 10px;
  left: 22px;
  z-index: 2; }

.DateInput_fangShape {
  fill: #fff; }

.DateInput_fangStroke {
  stroke: #dbdbdb;
  fill: transparent; }

.DateRangePickerInput__disabled {
  background: #f2f2f2; }

.DateRangePickerInput__rtl {
  direction: rtl; }

.DateRangePickerInput__block {
  display: block; }

.DateRangePickerInput__showClearDates {
  padding-right: 30px; }

.DateRangePickerInput_arrow {
  display: inline-block;
  vertical-align: middle;
  color: #484848; }

.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #484848;
  height: 24px;
  width: 24px; }

.DateRangePickerInput_clearDates {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

.DateRangePickerInput_clearDates__small {
  padding: 6px; }

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: #dbdbdb;
  border-radius: 50%; }

.DateRangePickerInput_clearDates__hide {
  visibility: hidden; }

.DateRangePickerInput_clearDates_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle; }

.DateRangePickerInput_clearDates_svg__small {
  height: 9px; }

.DateRangePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 0.25rem;
  padding: 0; }

.DateRangePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle; }

.DateRangePicker {
  position: relative;
  display: inline-block; }

.DateRangePicker__block {
  display: block; }

.DateRangePicker_picker {
  z-index: 1; }

.DateRangePicker_picker__rtl {
  direction: rtl; }

.DateRangePicker_picker__directionLeft {
  left: 0; }

.DateRangePicker_picker__directionRight {
  right: 0; }

.DateRangePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; }

.DateRangePicker_picker__fullScreenPortal {
  background-color: #fff; }

.DateRangePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2; }

.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
  color: #b0b3b4;
  text-decoration: none; }

.DateRangePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd; }

.DateRangePicker,
.DateRangePicker_1 {
  margin-bottom: 12px; }

.DateRangePickerInput {
  min-width: 226px;
  height: 32px;
  min-height: 32px;
  border-radius: 6px;
  background-color: #dfeef2;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000000;
  display: flex;
  justify-content: space-around;
  align-items: center; }

.DateRangePickerInput__withBorder {
  border: 1px solid #4eb3d4; }

.DateRangePickerInput_arrow > svg {
  display: none; }

.DateRangePickerInput_arrow:after {
  color: white;
  margin: 0;
  font-weight: 700;
  content: '|'; }

.DateRangePickerInput_calendarIcon > svg {
  display: none; }

.DateRangePickerInput_calendarIcon:after {
  content: url("https://puzzle-media.s3.amazonaws.com/calendarIcon.svg"); }

.CalendarMonth_table {
  border-spacing: 0 5px;
  border-collapse: separate; }
  .CalendarMonth_table tbody {
    margin: 1rem; }

.DateInput_fang,
.DateInput_fang {
  display: none; }

.DateRangePicker_picker,
.DateRangePicker_picker_1 {
  top: 35px !important;
  max-width: 665px;
  max-height: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 10px 0 #d1dadc;
  background-color: #ffffff;
  position: absolute;
  padding: 28px 10px 36px; }

.DayPickerNavigation_leftButton__horizontalDefault {
  top: 150px;
  left: -10px; }

.DayPickerNavigation_rightButton__horizontalDefault {
  top: 150px;
  right: -10px; }

.DayPickerNavigation_button__default:hover,
.DayPickerNavigation_button__default:focus {
  border: 0;
  outline: 0;
  background-color: transparent;
  color: black; }

.DayPickerNavigation_button__default {
  border: 0;
  background-color: transparent;
  color: black; }

.DayPickerNavigation_button__default > svg {
  display: none; }

.DayPickerNavigation_button__default.DayPickerNavigation_leftButton__horizontalDefault:after {
  content: url("https://puzzle-media.s3.amazonaws.com/chevron-left.svg"); }

.DayPickerNavigation_button__default.DayPickerNavigation_rightButton__horizontalDefault:after {
  content: url("https://puzzle-media.s3.amazonaws.com/chevron-right.svg"); }

.DayPicker__withBorder {
  box-shadow: none; }

.CalendarDay.CalendarDay__default {
  border: 0;
  font-size: 11px;
  padding: 0; }

.CalendarDay.CalendarDay__default:hover,
.CalendarDay__hovered_span {
  background: #dfeef2;
  color: black; }

.CalendarDay.CalendarDay__default.CalendarDay__selected:hover {
  border: 0;
  font-size: 11px;
  padding: 0;
  background: #4eb3d4;
  color: white; }

.CalendarMonth_caption {
  text-align: left;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #173042; }

.DayPickerKeyboardShortcuts_show__bottomRight {
  display: none; }

.CalendarDay__selected,
.CalendarDay__selected:active {
  background-color: #4eb3d4;
  border: 0;
  color: #fff;
  border-radius: 100% 0 0 100%; }

.CalendarDay__selected:hover {
  background-color: #4eb3d4;
  border: 0;
  color: #fff; }

.CalendarDay__selected:focus {
  outline: none; }

.CalendarDay__selected_end {
  border-radius: 0 100% 100% 0; }

.CalendarDay__selected_span {
  background: #dfeef2;
  color: black; }

.CalendarDay__selected_span:hover {
  background: #dfeef2;
  outline: 1px solid #4eb3d4;
  color: black; }

li {
  list-style-type: none; }

.ReportsFilter__container {
  min-height: 90px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 #e8eaec;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3; }

.ReportsFilter__inner {
  width: 100%;
  padding: 20px 24px 12px;
  display: flex;
  justify-content: space-between; }
  .ReportsFilter__inner .ReportsFilter__timeframe {
    width: 226px; }
  .ReportsFilter__inner label {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: #000000; }
  .ReportsFilter__inner .ReportsFilter__AddFilterButton_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
  .ReportsFilter__inner .ReportsFilter__AddFilterButton {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-width: 70px;
    height: 32px;
    border-radius: 5px;
    background-color: #dfeef2;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.29;
    letter-spacing: normal;
    color: #173042;
    cursor: pointer;
    margin: 0 12px 12px 0;
    z-index: 2; }
  .ReportsFilter__inner .ReportsFilter__FilterButtons_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-top: 12px;
    flex: 1;
    z-index: 2; }
  .ReportsFilter__inner .ReportsFilter__FilterButton_inner {
    display: none;
    margin: 0 12px 0 0; }
  @media screen and (max-width: 1560px) {
    .ReportsFilter__inner .ReportsFilter__FilterButton_inner {
      margin: 12px 12px 0 0; } }
  .ReportsFilter__inner .ReportsFilter__FilterButton_inner.show {
    display: block; }
  .ReportsFilter__inner .ReportsFilter__FilterButton_container {
    display: flex;
    justify-content: center;
    position: relative; }
    .ReportsFilter__inner .ReportsFilter__FilterButton_container:hover > .ReportsFilter__RemoveFilterButton {
      display: flex; }
  .ReportsFilter__inner .ReportsFilter__RemoveFilterButton {
    position: absolute;
    display: none;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    top: -15px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.33);
    border: solid 0 #173042;
    border-radius: 100%;
    background-color: #fff;
    transition: all 0.5s;
    cursor: pointer;
    text-align: center;
    font-size: 9px; }
    .ReportsFilter__inner .ReportsFilter__RemoveFilterButton p {
      margin-top: 0;
      margin-bottom: 0;
      padding: 0 5px;
      min-width: 100px;
      display: flex;
      justify-content: space-evenly;
      align-items: center; }
  .ReportsFilter__inner .ReportsFilter__RemoveFilterButton:hover {
    width: 100px;
    border-radius: 25px; }
  .ReportsFilter__inner .ReportsFilter__FilterButton {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 32px;
    border-radius: 5px;
    background-color: #edf0f2;
    cursor: pointer;
    padding: 0 8px; }
    .ReportsFilter__inner .ReportsFilter__FilterButton.focus {
      border-radius: 7px;
      box-shadow: 0 0 4px 0 rgba(38, 38, 38, 0.25);
      border: solid 1px #a2acb3; }
    .ReportsFilter__inner .ReportsFilter__FilterButton .fa-chevron-right {
      font-size: 11px;
      line-height: 10px; }
    .ReportsFilter__inner .ReportsFilter__FilterButton p {
      font-size: 12px;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.29;
      letter-spacing: normal;
      color: #173042;
      margin: 0; }
    .ReportsFilter__inner .ReportsFilter__FilterButton span {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.29;
      letter-spacing: normal;
      color: #173042;
      margin-right: 15px; }
  .ReportsFilter__inner .ReportsFilter__AddFilterDropdown {
    position: absolute;
    top: 235px;
    width: 130px; }
    .ReportsFilter__inner .ReportsFilter__AddFilterDropdown .ReportsFilter__FilterDropdownItem:hover {
      cursor: pointer;
      background-color: #dfeef2;
      border-radius: 4px; }
  .ReportsFilter__inner .ReportsFilter__FilterDropdown {
    position: absolute;
    width: 100%;
    max-width: 200px; }
  .ReportsFilter__inner .ReportsFilter__FilterDropdown::-webkit-scrollbar {
    width: 8px; }
  .ReportsFilter__inner .ReportsFilter__FilterDropdown::-webkit-scrollbar-thumb {
    height: 54px;
    border-radius: 10px;
    box-shadow: 0 0 6px 0 #acacac;
    background-color: #ffffff; }
  .ReportsFilter__inner .ReportsFilter__FilterDropdown,
  .ReportsFilter__inner .ReportsFilter__AddFilterDropdown {
    height: 230px;
    border-radius: 10px;
    box-shadow: 0 4px 10px 0 #d1dadc;
    background-color: #ffffff;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 9999;
    padding: 5px; }
  .ReportsFilter__inner .ReportsFilter__FilterDropdown__background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    color: red;
    height: 100%;
    width: 100%; }
  .ReportsFilter__inner .ReportsFilter__FilterDropdownItem {
    font-size: 12px;
    color: #000000;
    padding: 5px; }
  .ReportsFilter__inner .ReportsFilter__Timeframe {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 226px;
    height: 32px;
    min-height: 32px;
    border-radius: 5px;
    background-color: #dfeef2;
    color: #000000;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.67;
    letter-spacing: normal; }
    .ReportsFilter__inner .ReportsFilter__Timeframe .ReportsFilter__date {
      display: flex;
      justify-content: space-around;
      margin: 0 0.5rem; }
      .ReportsFilter__inner .ReportsFilter__Timeframe .ReportsFilter__date .ReportsFilter__StartDate {
        margin-right: 0.5rem; }

.bp3-control .bp3-control-indicator {
  height: unset;
  width: unset;
  border: 2px solid #000; }

.bp3-control input:checked ~ .bp3-control-indicator {
  background-color: #4eb3d4 !important; }

.bp3-control input:checked .bp3-control-indicator {
  background-color: #4eb3d4 !important;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
  color: #ffffff; }
